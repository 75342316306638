<template>
    <p class="h20"></p>
    <div class="bgf padd20" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="flex flexsb flexas">
            <p class="col24 font14 lh22 w764 padr20">{{info.problemProfile}} </p>
            <div class="flex flexa">
                <span @click="colect" class="point" >
                    <img v-if="info.isCollection==1" :src="utils.loadImg('xing-a.png')" alt=""  /> 
                    <img v-else :src="utils.loadImg('xing.png')" alt=""  /> 
                </span>
                <span class="col24 font12 padl30">上次更新时间: {{info.lastUpdateTime}} </span>
            </div>
        </div>
        <div class="flexab padt20 ">
            <div class="font12 padr20">
                <span class="colb1">当前工单状态：</span>
                <span class="colblue"> 
                    {{ statusL[info.status] }}
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="colb1">工单编号：</span>
                <span class="col24">{{info.outTradeNo}} </span>

                <template v-if="info.workOrderType==2">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span class="colb1 ">严重等级：</span>&nbsp;
                    <span :class="[ 'coll'+info.problemSeverity ,'dian']"></span>&nbsp;
                    <span class="col24">{{ ['','服务完全丢失','服务严重丢失','少量丢失','未丢失服务'][info.problemSeverity] }}</span>&nbsp;
                    <span @click="upgrade=true" class="colzhuti mat-1 underline">等级调整 ></span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span class="colb1">升级状态：</span>
                    <span class="colzhuti"> {{['未升级','已升级'][info.upgradeStatus] }}</span>&nbsp;&nbsp;
                    <span v-if="info.upgradeStatus==1" @click="lookReason" class="colblue underline point">查看升级原因 ></span>
                </template>
            </div>
            <div class="nowrap">
                <el-button v-if="info.status==4 && info.isCanOpenAgain==1 " @click="reopen=true" size="small">重开工单</el-button>
                <el-button v-if="info.workOrderType==2 && info.upgradeStatus==0 && info.status!=4 && info.status!=13 " @click="upgrade2=true; upgrade2Data={};"  size="small">工单升级</el-button>
                <template v-if="info.status!=4 && info.status!=13">
                    <el-button @click="jiedan = true" size="small">结单</el-button>
                    <el-button @click="fujian = true;fileL=[];fujianData={ attachPath:[] }" size="small" type="primary">+添加附件</el-button>
                    <el-button @click="addfankui " size="small" type="primary">+添加反馈</el-button>
                </template>
            </div>
        </div>

    </div>
    <p class="h20"></p>
    <div class="flex flexas">
        <div class="left" v-loading="arrloading">
            <div class="bgec h60 flexab pad20 col24">
                <span class="font14 nowrap padr30">沟通记录</span>
                <div class="font12">
                    <label for="">时间排序：</label>
                    <el-select size="small" class="maxw150" v-model="param.sortWay" @change="getList" clearable placeholder="请选择">
                        <el-option label="时间升序" value="asc"></el-option>
                        <el-option label="时间降序" value="desc"></el-option>
                    </el-select> &nbsp;&nbsp;&nbsp;
                    <label for="">筛选依据：</label>
                    <el-select size="small" class="maxw150" v-model="param.VDMCode" @change="getList" placeholder="请选择">
                        <el-option label="显示全部条目" value=""></el-option>
                        <el-option
                        v-for="(item,index) in options"
                        :key="index"
                        :label="item"
                        :value="item">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="bgf marb10 padd20">
                <div class="flex flexa">
                    <img :src="detail.headPic" alt="" width="40" height="40" class="borahalf" srcset="">
                    <p class="col6 padl15 fontw6">{{detail.username}} &nbsp;&nbsp;【{{detail.vDMCode}}】 </p>
                </div>
                <div class="padl55 ">
                    <p class="col2 lh16 padb7"><span class="col0 fontw6 nowrap">【问题概要】</span>{{detail.problemProfile}} </p>
                    <p class="col2 lh16 padb7 flex"><span class="fontw6 col0 nowrap">【问题描述】</span> <span v-html="detail.problemDescription" ></span> </p>
                    <!-- <div  class="padt10 padb16">
                        <p class="fontw6 col0 padb14">【错误代码】</p>
                    </div> -->
                    <div v-if="detail.problemImages.length>0" class="padt10 padb16">
                        <p class="fontw6 col0 padb14">【问题截图】</p>
                        <div class="border padt40 padb40 padl70 padr40">
                            <el-image
                                v-for="(item,index) in detail.problemImages"
                                :key="index"
                                style="width: 30%; margin-right:3%;"
                                :src="item"
                                :preview-src-list="detail.problemImages"
                            >
                            </el-image>
                        </div>
                    </div>
                    <template v-if="detail.attachmentPath.length>0" >
                        <div class="col2 lh20 padb7 flex" >
                            <span class="col0 fontw6" style="width:70px" >【附件】</span> 
                            <div>
                                <p 
                                v-for="(item,index) in detail.attachmentPath"
                                :key="index"
                                @click="downloadtxt(item.downloadPath)" 
                                class="colblue point "> 
                                    {{ item.lookPath.split('/').pop() }} 
                                </p> 
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div v-for="(item,index) in list" :key="index" class="bgf marb10 padd20">
                <div class="flex flexa">
                    <img :src="item.headPic" alt="" width="40" height="40" class="borahalf" srcset="">
                    <p class="col6 padl15">{{item.username}} &nbsp; — &nbsp; {{item.createTime}} &nbsp;&nbsp; <span v-if="item.vDMCode">【{{item.vDMCode}}】</span> </p>
                </div>
                <div class="padl55 padt10 col2 lh24">
                    <!-- {{item.content}} -->
                    <div class="vhtml" v-html=" item.content "></div>
                    <div v-if="item.descImage.length>0" class="padt20 padb10">
                        <div class="border padt40 padb40 padl70 padr40">
                            <el-image
                                v-for="(ite,index) in item.descImage"
                                :key="index"
                                style="width: 30%; margin-right:3%;"
                                :src="ite"
                                :preview-src-list="item.descImage"
                            >
                            </el-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right">
            <p class="col24 font14 padb20">工单概要</p>
            <div class="flex font12 padb30 ">
                <p class="col9a nowrap">问题概要：</p>
                <p class="col2 lh16">{{info.problemProfile}} </p>
            </div>
            <ul class="ul1">
                <li v-if="info.workOrderType==2"> <span>严重等级</span> <span :class="[ 'coll'+info.problemSeverity ,'dian']"></span> 
                    {{ ['',' 服务完全丢失',' 服务严重丢失',' 少量丢失',' 未丢失服务'][info.problemSeverity] }}
                </li>
                <li> <span>状态</span>{{ statusL[info.status] }}</li>
                <li> <span>升级状态</span>{{['未升级','已升级'][info.upgradeStatus] }}</li>
                <li> <span>创建时间</span>{{info.createTime}} </li>
                <li> <span>上次更新时间</span>{{info.lastUpdateTime}}</li>
            </ul>
            <ul class="ul1">
                <li>
                    <span>相关附件</span>
                    <span @click="openDialog( 'openFujian' )" v-if="info.attachmentPath.length>0" class="colzhuti point">查看相关附件</span> 
                    <span v-else >无相关附件</span> 
                </li>
                <li> 
                    <span>相关链接</span>
                    <span v-if="info.relatedLinks.length>0" @click="openDialog( 'links' )" class="colzhuti point">查看相关链接</span>
                    <span v-else >无相关链接</span> 
                </li>
                <li> 
                    <span>相关知识文档</span>
                    <span v-if="info.knowledgeDocId.length>0" @click="openfiles(1)" class="colzhuti point">查看相关知识文档</span>
                    <!-- <span v-if="info.knowledgeDocId.length>0" @click="files=true" class="colzhuti point">查看相关知识文档</span> -->
                    <span v-else >无相关知识文档</span> 
                </li>
                <li> 
                    <span>相关Bug文档</span>
                    <span v-if="info.knowLedgeBugDocId.length>0" @click="openfiles(2)" class="colzhuti point">查看相关Bug文档</span>
                    <!-- <span v-if="info.knowLedgeBugDocId.length>0" @click="files=true" class="colzhuti point">查看相关Bug文档</span> -->
                    <span v-else >无相关Bug文档</span> 
                </li>
            </ul>
            <ul class="ul1">
                <li> <span>客户服务号</span>{{info.csiNumber}} </li>
                <li> <span>账户名</span>{{info.companyName}} </li>
                <li> <span>工单联系人</span>{{info.mainContact}}&nbsp;&nbsp;&nbsp;<span v-if="info.status!=4 && info.status!=13" @click="toEdit(1)" class="colzhuti point">编辑</span> </li>
                <li v-if="info.problemSeverity==1"><span>备用联系人</span>{{info.standbyContact}}&nbsp;&nbsp;&nbsp;<span class="colzhuti point" @click="toEdit(2)" v-if="info.status!=4 && info.status!=13" >编辑</span></li>
            </ul>
            <ul class="ul1">
                <template v-if="info.workOrderType==2">
                    <li> <span>硬件平台</span>{{info.hardwarePlatform}} </li>
                    <li> <span>操作系统</span>{{info.operatingSystem}} </li>
                    <li> <span>操作系统版本</span>{{info.operatingSystemVersion}}</li>
                    <li> <span>系统语言</span>{{info.operatingSystemLanguage}}</li>
                    <li> <span>部署方式</span>{{info.deploymentWay}}</li>
                </template>
                <li> <span>所属模块</span>{{info.productNameVersion}}</li>
                <li > <span>问题类型</span>{{info.problemType}}</li>
                <template v-if="info.workOrderType==2">
                    <li> <span>产品名称</span>{{info.productName}}</li>
                    <li> <span>版本名称</span>{{info.versionName}}</li>
                </template>
            </ul>
        </div>
    </div>

    <el-dialog title="添加工单附件" width="700px" v-model="fujian">
        <ul class="sylform" v-loading="loading">
            <li class="li1">
                <label for="">问题附件</label>
                <div>
                    <ul class="ulfile">
                        <li v-for="(item,index) in fileL" :key="index" class="">
                            <span class="col63">{{item.name}}</span>
                            <span @click="delfile(index,'fileL')" class="colred point">删除</span>
                        </li>
                    </ul>
                    <div class="positionr inbl">
                        <input type="file"  
                        @change="handleFile('image2','fileL')" id="image2" class="hiddenInput"  />
                        <el-button type="primary" size="small" >添加附件</el-button>
                    </div>
                    <p class="col8f font12 padt4">支持.png、.jpg、.jpeg、.txt、.rar、.doc、.xls、.xlsx、.zip格式，最大不超过50M</p>
                </div>
            </li>
            <li class="li1">
                <label for="" >附件注释</label>
                <el-input v-model="fujianData.attachDescription" 
                type="textarea" :rows="6" placeholder="请输入添加附件的注释" class="w515" size="small" ></el-input>
            </li>
            <li>
                <label for=""></label>
                <div class="tl padt20"  >
                    <el-button size="small" @click="fujian = false">取 消</el-button>
                    <el-button size="small"  type="primary" @click="fujianSub">提 交</el-button>
                </div>
            </li>
        </ul>
    </el-dialog>

    <el-dialog title="添加工单反馈" width="700px" v-model="fankui" >
        <ul class="sylform " v-loading="loading">
            <li class="li1">
                <label for="">工单反馈<em>*</em></label>
                <!-- <div id="fankui" class="w515" style="z-index:7;"  >
                </div> -->

                <kindeditor
                :content="fankuiHtml"
                width="515px"
                height="300px"
                @base64Html="base64Flie($event,'fankuiHtml')"
                id="kind1"
                @on-content-change="onContentChange($event,'fankuiHtml')"
                ></kindeditor>
            </li>
            <li class="padt20">
                <label for=""></label>
                <div class="">
                    <el-button size="small" @click="fankui = false">取 消</el-button>
                    <el-button size="small"  type="primary" @click="fankuiSub">提 交</el-button>
                </div>
            </li>
        </ul>
    </el-dialog>

    <el-dialog :title=" accountData.type==1?'编辑联系人信息':'编辑备用联系人信息' " width="520px" v-model="edit">
        <ul class="sylform ">
            <li>
                <label for="">主要联系人<em>*</em></label>
                <el-input v-model="accountData.mainContact" placeholder="请输入联系人" class="w240" size="small" ></el-input>
            </li>
            <li>
                <label for="">手机号<em>*</em></label>
                <el-input v-model="accountData.mainMobile" placeholder="请输入手机号" class="w240" size="small" ></el-input>
            </li>
            <li>
                <label for="">邮箱号<em>*</em></label>
                <el-input v-model="accountData.mainEmail" placeholder="请输入邮箱号" class="w240" size="small" ></el-input>
            </li>
            <li v-if="accountData.type==1">
                <label for="">联系方式<em>*</em></label>
                <el-select size="small" class="minw240" v-model="accountData.contactWay" placeholder="请选择联系方式">
                    <el-option label="手机号" :value="1"></el-option>
                    <el-option label="邮箱号" :value="2"></el-option>
                </el-select>
            </li>
            <li>
                <label for=""></label>
                <span class="padt20 ">
                    <el-button size="small" @click="edit = false">取 消</el-button>
                    <el-button size="small"  type="primary" @click="editSub">提 交</el-button>
                </span>
            </li>
        </ul>
    </el-dialog>

    <el-dialog title="确认结单" width="685px" v-model="jiedan">
        <p class="col65 padl20">确认结单后，您将无法对该工单进行问题补充或留言反馈</p>
        <ul class="sylform padt30">
            <li >
                <label for="">关闭工单理由</label>
                <el-radio-group v-model="jiedanData.closeReason" class="w100">
                    <el-radio label="方案已提供">方案已提供</el-radio>
                    <el-radio label="已自行解决">已自行解决</el-radio>
                    <el-radio label="放弃解决">放弃解决</el-radio>
                </el-radio-group>
            </li>
            <li class="li1">
                <label for="" >结单反馈</label>
                <el-input v-model="jiedanData.closeFeedback" 
                type="textarea" :rows="6" placeholder="输入结单反馈" class="w515" size="small" ></el-input>
            </li>
            <li class="padt20">
                <label for="" ></label>
                <el-button size="small" @click="jiedan = false">取 消</el-button>
                <el-button size="small"  type="primary" @click="jiedanSub">确 定</el-button>
            </li>
        </ul>
        
    </el-dialog>

    <el-dialog title="等级调整" width="685px" v-model="upgrade">
        <p class="col65 padl20">确认要对你的工单等级进行调整么？调整后技术支持将会调整，从而对你的工单进行解决</p>
        <ul class="sylform labw120 padt30">
            <li >
                <label for="">选择要调整的等级</label>
                <el-radio-group v-model="upgradeData.problemSeverity" class="w100">
                    <el-radio class="padb20" :label="1">U1-服务完全丢失</el-radio>
                    <el-radio :label="2" class="padb20" >U2-服务严重丢失</el-radio>
                    <el-radio :label="3" class="padb20" >U3-服务少量丢失</el-radio>
                    <el-radio :label="4" class="padb20" >U4-未丢失服务</el-radio>
                </el-radio-group>
            </li>
            <template v-if="upgradeData.problemSeverity==1">
                <p class="padb20 padl20 padt10"><span class="col24 fontw6">添加备用联系人联系方式</span> &nbsp;&nbsp;<span class="colred font10">注：由于您的工单等级是U1严重需要添加备用联系人</span> </p>
                <li >
                    <label for="" >备用联系人<em>*</em></label>
                    <el-input v-model="upgradeData.standbyContact" 
                    placeholder="请输入联系人姓名" class="w240" size="small" ></el-input>
                </li>
                <li >
                    <label for="" >手机号<em>*</em></label>
                    <el-input v-model="upgradeData.standbyMobile" 
                    placeholder="请输入手机号" class="w240" size="small" ></el-input>
                </li>
                <li >
                    <label for="" >邮箱号<em>*</em></label>
                    <el-input v-model="upgradeData.standbyEmail" 
                    placeholder="请输入邮箱号" class="w240" size="small" ></el-input>
                </li>
            </template>
        </ul>
        <template #footer>
            <div class="tl padb30" style="padding-left:150px">
                <el-button size="small" @click="upgrade = false">取 消</el-button>
                <el-button size="small"  type="primary" @click="upgradeSub">提 交</el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog title="工单升级" width="685px" v-model="upgrade2">
        <p class="col65 padl20">确认要对你的工单进行升级么？升级后技术支持将会升级，从而对你的工单进行解决</p>
        <ul class="sylform  padt30">
            <li class="li1">
                <label for="">升级原因</label>
                <el-input v-model="upgrade2Data.reason" 
                type="textarea" :rows="6" placeholder="输入升级原因" class="w515" size="small" ></el-input>
            </li>
            <li >
                <label for=""></label>
                <div class="tl padt20" >
                    <el-button size="small" @click="upgrade2 = false">取 消</el-button>
                    <el-button size="small"  type="primary" @click="upgrade2Sub">提 交</el-button>
                </div>
            </li>
        </ul>
    </el-dialog>

    <el-dialog :title=" documentType==1 ? '工单相关知识文档':'工单相关Bug文档' " v-model="files">
        <el-table
        ref="links"
        :data="documentL"
        :header-cell-style="{ background: '#F1F1F1'}"
        height="450" 
        class="border fujian"
        style="width: 100%">
            <el-table-column
                prop="documentNumber"
                label="文档编号"
                width="180">
            </el-table-column>
            <el-table-column
            label="文档标题"
            show-overflow-tooltip
            >
              <template #default="scope">
                  <el-link :href=" '#/knowledgeInfo/'+scope.row.id " target="_blank" style="display:unset" class="text1 " >{{scope.row.docTitle}}</el-link>
                </template>
            </el-table-column>
        </el-table>
        
    </el-dialog>

    <el-dialog title="相关链接" width="685px" v-model="links">
        <el-table
        ref="links"
        height="450" 
        :data="info.relatedLinks"
        :header-cell-style="{background: '#F1F1F1'}"
        class="border fujian"
        style="width: 100%">
            <el-table-column
            prop="address"
            label="链接地址"
            class-name="padl20 padr20"
            show-overflow-tooltip
            >
              <template #default="scope">
                  <el-link :href="scope.row" target="_blank" style="display:unset" class="text1 " >{{scope.row}}</el-link>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>

    <el-dialog title="相关附件" width="685px" v-model="openFujian">
        <el-table
        height="450" 
        ref="openFujian"
        :data="info.attachmentPath"
        :header-cell-style="{background: '#F1F1F1'}"
        class="border fujian"
        style="width: 100%">
            <el-table-column
            label="附件地址"
            class-name="padl20 padr20"
            show-overflow-tooltip
            >
                <template #default="scope">
                  <span class="text1 block">{{ decodeURIComponent(scope.row.split('/').pop())}}</span>
                </template>
            </el-table-column>
            <el-table-column
            label=""
            fixed="right"
            width="80"
            >
                <template #default="scope">
                  <span @click="downloadtxt(scope.row)" class="colzhuti point block text1">下载</span>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>

    <el-dialog title="重开工单" width="685px" v-model="reopen">
        <ul class="sylform " style="padding:0" >
            <li class="li1">
                <label for="">重开工单原因 <em>*</em></label>

                <el-input v-model="reopenData.openAgainReason" 
                type="textarea" :rows="6" placeholder="输入重开工单原因" class="w515" size="small" ></el-input>
            </li>
            <!-- <li class="li1">
                <label for="" >其它详细资料</label>
                <el-input v-model="reopenData.openAgainDesc" 
                type="textarea" :rows="6" placeholder="请输入添加附件的注释" class="w515" size="small" ></el-input>
            </li> -->
        </ul>
        <template #footer>
            <span class="dialog-footer ">
                <el-button size="small" @click="reopen = false">取 消</el-button>
                <el-button size="small"  type="primary" @click="reopenSub">提 交</el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog title="工单升级原因" width="700px" center v-model="upReason">
        <div class="tc">
            {{upReasonData}}
        </div>
        <div class="tc padt40 padb10" >
            <el-button size="small" @click="upReason = false">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { upgradeOrder, feedbackAddAttachment, createFeedback, workOrderDetail, communicationRecord, download, collectionWorkOrder, changeSeverity, createAttach, upgradeReason, changeOrder, closeOrder, openAgain } from "@/utils/api2.js"
import { picUpload, addAttachment} from '@/utils/api1.js'
import E from 'wangeditor'

export default {
    data(){
        return{
            fullscreenLoading: false,
            fankuiHtml:'',
            editor:null,
            loading:false,
            arrloading:false,
            id: this.$route.params.id,
            info:{
                attachmentPath:[],  //附件
                knowLedgeBugDocId:[],  //相关bug文档
                knowledgeDocId:[],  //相关知识文档
                relatedLinks:[],  //相关链接
            },
            param:{
                workOrderId: this.$route.params.id,
            },
            detail:{
                problemImages:[],
                attachmentPath:[]
            },
            list: [],
            options: [ '客户问题描述', '客户已更新', 'VDM问题澄清', 'VDM问题证据', 'VDM问题原因', 'VDM诊断依据', 'VDM解决方案', 'VDM方案依据', 'VDM数据收集', 'VDM分析研究'],
            documentType: '', //文档类型
            documentL: [], //文档列表
            accountData:{}, //联系人信息
            upReason:false,  //工单升级原因
            upReasonData:"",
            edit: false,  //编辑联系人信息
            jiedan: false,  //确认结单
            jiedanData:{}, //结单
            upgrade: false, //工单等级调整
            upgradeData:{},
            upgrade2: false, //工单升级
            upgrade2Data:{},
            openFujian: false, //查看相关附件
            files: false,  //工单相关知识文档
            links: false,   //相关链接
            reopen: false,  //重开工单
            reopenData:{ },
            fujian:false, //添加工单附件
            fujianData:{ attachPath:[] },
            fankui:false, //添加工单反馈
            fankuiData:{  },
            descImage:[],
            fileL:[],
            statusL:{1:'待处理', 2:'进行中',3:'待反馈',4:'已关闭',5:'用户请求关闭',6:'支持工作中',7:'研发工作中',8:'待研发反馈',9:'复查更新',10:'客户工作中',11:'方案已提供',12:'内部反馈',13:'已取消'},
        }
    },
    watch: {
        '$route' (to, from) {
            console.log(to,from);
            if( to.name == 'info' && to.params.id!=from.params.id){
                this.id = this.$route.params.id;
                this.getData(); // 获取用户信息的方法
                this.getList();
            }
        }
    },
    mounted(){
        this.getData();
        this.getList();
    },
    methods:{

        // 提交重开工单
        reopenSub(){
            let that = this;

            if(!that.reopenData.openAgainReason){
                that.$message.warning('请输入重开工单原因');
                return
            }

            that.reopenData.workOrderId = that.id;

            console.log(that.reopenData)

            // return

            openAgain(that.reopenData).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    this.getData();
                }
                this.reopen = false;
            })
        },

        base64Flie (base64,key) {
            console.log(base64,key)
            this[key] +=`<img src="${base64}" alt="">`

        },

        onContentChange (val,key) {
            // console.log(val,key)
            this[key] = val;
            // console.log(this[key])
        },

        addfankui(){
            this.fankui = true;
            this.fankuiData={}; 
            this.bugfankui = true;
            this.fankuiHtml = '';
            // if(!this.editor ){
            //     this.$nextTick(()=>{
            //         this.editor = new E('#fankui');
            //         this.editor.config.height = 154;
            //         this.editor.config.menus = [
            //             'image'
            //         ];
            //         this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
            //             let imgFile = resultFiles[0];
            //             // console.log(resultFiles);
            //             let pic = new FormData();
            //             pic.append('file',imgFile);
            //             picUpload(pic).then(res=>{
            //                 console.log(res);
            //                 if(res){
            //                     insertImgFn(res.downloadPath)
            //                     // this.loading = false;
            //                 }
            //             })
            //         };
            //         // this.editor.config.uploadImgServer = '/api/api/login/pic-upload'
            //         this.editor.create();
            //     })
            // }else{
            //     this.editor.txt.clear();
            // }
        },

        // 结单提交
        jiedanSub(){
            this.jiedanData.workOrderId = this.id;
            console.log(this.jiedanData);
			let obj = JSON.parse(JSON.stringify(this.jiedanData));
			delete obj.closeFeedback;
            closeOrder(obj,this.jiedanData.closeFeedback).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    this.getData();
                }
                this.jiedan = false;
            })
        },

        // 编辑联系人信息
        toEdit(type){
            this.accountData.type = type;
            this.accountData.workOrderId = this.id;
            if(type==1){
                this.accountData.mainContact = this.info.mainContact  ;
                this.accountData.mainEmail = this.info.mainEmail  ;
                this.accountData.mainMobile = this.info.mainMobile  ;
                this.accountData.contactWay = this.info.contactWay  ;
            }else{
                this.accountData.mainContact = this.info.standbyContact  ;
                this.accountData.mainEmail = this.info.standbyEmail  ;
                this.accountData.mainMobile = this.info.standbyMobile  ;
            };

            this.edit = true;
        },
        
        // 提交编辑联系人信息
        editSub(){
            console.log(this.accountData);
            changeOrder(this.accountData).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    this.getData();
                }
                this.edit = false;
            })
        },

        // 查看升级原因
        lookReason(){
            upgradeReason(this.id).then(res=>{
                console.log(res);
                if(res){
                    this.upReasonData = res;
                    this.upReason = true;
                }
            })
        },

        // 添加工单反馈提交
        fankuiSub(){
            this.loading = true;
            this.fankuiData.workOrderId = this.id;
            // this.fankuiData.descImage = this.descImage.join(',');
            console.log(this.fankuiData);
            // let content = this.editor.txt.html();
            let content = this.fankuiHtml;
            console.log(content)

            if(!content){
                this.$message.warning('请输入反馈内容！');
                return;
            }

            createFeedback(this.fankuiData,{feedbackContent:content}).then(res=>{
                this.loading = false;
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    this.getList();
                    this.fankui = false;
                }
            });
        },

        // 添加工单附件提交
        fujianSub(){
            if(this.fileL.length==0){
                this.$message.warning('请上传附件！');
                return;
            }

            this.fujianData.workOrderId = this.id;
            this.fujianData.attachPath = this.fujianData.attachPath.join(',');

            console.log(this.fujianData);
            createAttach(this.fujianData).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    this.getList();
                    this.getData();
                    this.fujian = false;
                }
            });
        },

        openfiles(type){
            this.documentType = type;
            if(type==1){
                this.documentL = this.info.knowledgeDocId;
            }else if(type==2){
                this.documentL = this.info.knowLedgeBugDocId;
            };
            // this.files = true;
            this.openDialog('files');
        },

        // 工单升级提交
        upgrade2Sub(){
            this.upgrade2Data.workOrderId = this.id;
            console.log(this.upgrade2Data);

            if(!this.upgrade2Data.reason){
                this.$message.warning('请输入工单升级原因！');
                return;
            }

            upgradeOrder(this.upgrade2Data).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    this.getData();
                }
            });
            this.upgrade2 = false;
        },

        // 等级调整提交
        upgradeSub(){
            this.upgradeData.workOrderId = this.id;
            console.log(this.upgradeData);

            if(this.upgradeData.problemSeverity==1){
                if(!this.upgradeData.standbyContact){
                    this.$message.warning('请输入备用联系人！');
                    return;
                }else if(!this.upgradeData.standbyMobile){
                    this.$message.warning('请输入备用联系人手机号！');
                    return;
                }else if(!this.upgradeData.standbyEmail){
                    this.$message.warning('请输入备用联系人邮箱号！');
                    return;
                }
            }

            changeSeverity(this.upgradeData).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    this.getData();
                }
            });
            this.upgrade = false;
        },
        colect(){
            collectionWorkOrder(this.id).then(res=>{
                console.log(res);
                if(res){
                    this.$message.success(res.message);
                    if(this.info.isCollection==0){
                        this.info.isCollection = 1;
                    }else{
                        this.info.isCollection = 0;
                    }
                }
            });
        },

        downloadtxt(path){
            console.log(path)
            let fileSuffix = path.split('.').pop();//文件名后缀。
            // let filename = path.split('/').pop() ;
            let filename = decodeURIComponent(path.split('/').pop()) ;
            console.log(fileSuffix, filename );

            if(fileSuffix=='txt'|| fileSuffix=='pdf' ||fileSuffix=='jpg'||fileSuffix=='jpeg'||fileSuffix=='png'){
                download(path).then(res=>{
                    console.log(res);
                    if(res.data){
                        let blob = new Blob([res.data], { type: `application/${fileSuffix}`}) // 假设文件为pdf
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;
                        link.click();
                        link.remove();
                    }
                })
            }else{
                let a = document.createElement('a');
                a.href = path;
                a.download = filename;
                a.click();
                a.remove();
            }
        },

        // 多图片选择
        handleFile(id, key) {
			this.loading = true;
			let imgFile = document.getElementById(id).files[0];
			console.log(imgFile);
            let obj = {};
            if(id=='image2'){
                obj.name= imgFile.name;
            };
			
            let pic = new FormData();
            pic.append('file',imgFile);
            addAttachment(pic,this.id).then(res=>{
                console.log(res);
                if(res){
                    obj.url = res.downloadPath;
                    if(id=='image2'){
                        this.fujianData.attachPath.push(res.downloadPath);
                        console.log('22',this.fujianData.attachPath)
                        this[key].push(obj);
                    }else{
                        this[key].push(res.downloadPath);
                    };
                }
                this.loading = false;
            })
        },

        delfile(idx,key){
            this[key].splice(idx,1);
            if(key=='fileL'){
                this.fujianData.attachPath.splice(idx,1);
            }
        },

        getList(){
            console.log(this.param)
            this.arrloading = true;
            communicationRecord(this.param).then(res=>{
                this.arrloading = false;
                console.log(res);
                if(res){
                    if(res.problem.problemDescription){
                        res.problem.problemDescription = this.utils.formatRichText(res.problem.problemDescription)
                    }
                    this.detail = res.problem;
                    this.list = res.recordList;
                }
            });
        },

        getData(){
            this.fullscreenLoading = true;
            workOrderDetail(this.id).then(res=>{
            this.fullscreenLoading = false;
                console.log(res);
                if(res){
                     
                    this.info = res;
                }else{
                    this.$router.push('/service');
                }
            });
        },

        openDialog(bol){
            this[bol] = true;

            this.$nextTick(()=>{
                // this[bol] = true;
                this.$refs[bol].doLayout()
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.left{
    width: calc( 100% - 337px );
}
.vhtml{
    width: 100%;
    overflow-x: auto;
}
.vhtml img{
    width: 100%;
}
.minw240{ min-width: 240px;}
.ul1{
    font-size: 12px;
    color: #222222;
    padding: 30px 0 16px 0;
    border-top: 1px dashed #D7D6D6;
    li{
        >span:nth-child(1){
            color: #9A9A9A;
            display: inline-block;
            width: 72px;
            min-width: 72px;
            text-align: right;
            padding-right: 25px;
        }
        padding-bottom: 14px;
    }

}
.right{
    min-width: 337px;
    width: 337px;
    box-sizing: border-box;
    margin-left: 20px;
    background: #fff;
    padding: 22px 16px;
}
.mat-1{
    margin-top: -4px;
    display: inline-block;
    cursor: pointer;
}
.borbotd{
    border-bottom: 1px dashed #D7D6D6;
}
.hiddenInput{
		opacity: 0;
		height: 30px;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}
</style>